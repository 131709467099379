.DZUpa {
    display: none;
}
.hFlVyB {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .hzTWok {
        display: none;
    }
    .iRumey {
        display: none;
    }
    
  }